.icon {
  display: flex;

  img {
    max-width: 48px;
    height: auto;
    margin-right: -11px;
  }
}

.bottomIcon {
  position: fixed;
  bottom: 8px;
  width: 100%;
  display: flex;

  img {
    width: 18px;
    height: auto;
    margin: 0 auto;
    border-radius: 50%;
    object-fit: cover;
  }
}
