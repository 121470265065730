.container {
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;

  .mailtoLink {
    display: inline-block;
    font-size: 18px;
    font-weight: 600;
    color: #003459; /* Default color */
    text-decoration: none;
    position: relative;
    padding: 4px 8px;
    transition: color 0.3s ease;
  
    &:hover {
      color: #FE8801; /* Hover color */
    }
  
    /* Underline animation */
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 2px;
      background-color: #FE8801; /* Underline color */
      transition: width 0.3s ease;
    }
  
    &:hover::after {
      width: 100%; /* Expands underline on hover */
    }
  }
}

