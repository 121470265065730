@import '../../../variables.scss';


.hero {
  display: flex;
  flex-direction: row;
  align-items: center;
  
  div {
    flex: 1;
    padding: 12px;
    text-align: center;
  }

  @media (max-width: 927px) {
    flex-direction: column;
  }

  .heroContent {
    background-color: #a7c5d3;
    border-radius: 8px;

    h1 {
      color: $primary-orange;
    }

    h3 {
      color: #fbfcf6;
      font-weight: 400;
    }

    .mainCta {
      background-color: #003459c2;
      border-radius: 32px;
      color: #FE8801;
      width: 300px;
      font-size: 28px;
      font-weight: 700;
      padding: 8px;
      border: none;
      cursor: pointer;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    
      transition: transform 0.3s ease, box-shadow 0.3s ease;
    
      &:hover {
        box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2), 0 4px 6px rgba(0, 0, 0, 0.1);
        transform: scale(1.02);
      }
    
      &:active {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        transform: scale(0.98);
      }
    }
  }

  .kangaroo {
    border-radius: 50%;
    object-fit: cover;
    display: flex;
    justify-content: center;

    @media (max-width: 927px) {
      margin-top: 40px;
    }

    img {
      width: 90%;
      max-width: 450px;
      height: auto;

      border-radius: 50%;
      object-fit: cover;
      border: 2px solid #ccc;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
  }
}